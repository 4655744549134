#footer{background:#1c202a;position:relative;padding:150px 0 30px;overflow:hidden;border-top:10px solid #f8a700;}
#footer.home-footer{overflow: visible;border-top:none;}
#footer.home-footer:before{content:"";height:820px;position:absolute;left:0;right:0;top:-819px;background:url('https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1642584988/mathlete/assets/winpanda-site-images/footer-before_xvuoih.png');background-repeat: no-repeat;background-position: center center;background-size:cover;}
#footer .container{position:relative;z-index: 3;}
#footer .footer-mountain-front,
#footer .footer-mountain-back{position:absolute;left:0;bottom:0;z-index:2;}
#footer .footer-mountain-back{z-index:1}
.footer-logo-holder strong{padding:0 15px;position:relative;}
.footer-logo-holder .pwrd-by{font-size:13px;line-height:15px;color:#fff;}
.footer-logo-holder .flag{position:absolute;top:0;width:95px;}
#footer.show-badges .footer-logo-holder .flag{-o-transform: scale(1,1);-ms-transform: scale(1,1);-moz-transform: scale(1,1);-webkit-transform: scale(1,1);transform: scale(1,1);}
.footer-logo-holder .flag-wwf{right:100%;}
.footer-logo-holder .flag-arhamsoft{left:100%;}
#footer .social-links,#footer .footer-nav,#footer .footer-contact-list{font-size:0;letter-spacing: 0;}
#footer .social-links li{width:37px;height:37px;margin:0 5px;font-size:21px;}
#footer .social-links li span{display: block;height:100%;border:1px solid #fff;background: #fff;border-radius:100%;color:#1c202a;}
#footer .social-links li span:hover{background:#1c202a;color:#f8a700;border-color:#f8a700}
#footer .footer-nav li,#footer .footer-contact-list li{font-size:16px;line-height:20px;margin:0 15px 15px;}
#footer .footer-nav li a,#footer .footer-contact-list a{color:#fff;}
#footer .footer-nav li a:hover, #footer .footer-contact-list a:hover{color:#f8a700;}
#footer .footer-contact-list{font-weight:300;color:#fff;}
#footer .footer-contact-list .contact-icon{font-size:17px;}
#footer .copyright-text{font-size:16px;line-height:20px;font-weight:300;color:#fff;}
.footer-logo-holder .flag,
#footer .footer-logo{-o-transform: scale(0,0);-ms-transform: scale(0,0);-moz-transform: scale(0,0);-webkit-transform: scale(0,0);transform: scale(0,0);transition: all ease-in-out 0.3s;}
#footer.show-badges .footer-logo-holder .flag,
#footer.show-badges .footer-logo{-o-transform: scale(1,1);-ms-transform: scale(1,1);-moz-transform: scale(1,1);-webkit-transform: scale(1,1);transform: scale(1,1);}
#footer .footer-logo{position:absolute;bottom:0;z-index:4;}
#footer .footer-logo.wwf-logo{left:0;}
#footer .footer-logo.arhamsoft-logo{right:0;}
.footer-logo-holder span.footer-svg{width:195px;height:auto;margin:-30px 0 0;}
#footer .apps-btns-holder img{width:128px;height:44px;border-radius:6px;}
@media only screen and (max-width:1199px){
    #footer{padding:50px 0 30px;}
    #footer.home-footer:before{display:none;}
}
@media only screen and (max-width:767px){
    #footer{padding:50px 0 80px;}
    .footer-logo-holder span.footer-svg{width:140px;}
}
@media only screen and (max-width:576px){
    #footer .footer-logo{width:50px;}
    #footer .copyright-text{padding:0 35px;}
    .footer-logo-holder strong{max-width:155px;padding:0 7px;}
    .footer-logo-holder .flag{width:60px;}
}