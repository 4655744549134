#header {padding:17px 0;transition: all ease-in-out .5s;z-index: 997;-webkit-animation-duration: .5s;animation-duration: .5s;transition: .5s;animation-direction: reverse;animation-duration: .3s;min-height:106px;background:#1c202a; position: relative;}
#header.home-header{position:absolute;left:0;top:0;right:0;background:none;}
#header.sticky {position: fixed;left:0;top:0;right:0;z-index: 999999;background:#1c202a;-webkit-animation-name: stickySlideDown;animation-name: stickySlideDown;transition: none;animation-direction: normal;animation-duration: .5s;}
#header .container{max-width:1638px;}
#header .main-menu {padding-top: 0;padding: 0 40px;}
#header.highZindex {z-index: 999999;}
#header.sticky.highZindex {z-index: 999999999;}
#header .logo,
#header .logo a{display:inline-block;vertical-align: top;}
#header .logo img{width:194px;}
#header .navbar {width: 100%;padding-top: 5px;padding-bottom: 5px;min-height: auto;font-size:16px;line-height:19px;}
#header .navbar li.nav-item a,#header .navbar li.nav-item span {color: #fff;font-weight: 600;font-size: 14px;line-height: 20px;}
#header .navbar li.nav-item a,#header .navbar li.nav-item span {cursor: pointer;}
#header .navbar li.nav-item:hover a,#header .navbar li.nav-item:hover span,
#header .navbar li.nav-item a:hover,#header .navbar li.nav-item span:hover,#header .navbar li.nav-item.show a {color: #fff;}
#header .navbar li.nav-item.show span {color: #fff;}
#header .navbar .left-nav{padding:9px 0 0 0;}
#header .navbar .left-nav li{position:relative;}
#header .navbar .left-nav li:after{content:"";width:0;height:2px;position:absolute;left:0;right:0;bottom:-10px;background:#fff;-o-transition:all ease-in-out 0.2s;-ms-transition:all ease-in-out 0.2s;-moz-transition:all ease-in-out 0.2s;-webkit-transition:all ease-in-out 0.2s;transition:all ease-in-out 0.2s;}
#header .navbar .left-nav li.show:after,#header .navbar .left-nav li:hover:after{width:100%;}
#header .navbar li.nav-item.button {border: 2px solid;border-radius: 30px;margin-left: 10px;text-align: center;transition: all .3s ease-in;-webkit-transition: all .3s ease-in;box-shadow:0 0 11px rgba(0,0,0,0.25);}
#header .navbar li.nav-item.button a,#header .navbar li.nav-item.button span{text-decoration:none;display:block;}
#header .navbar li.nav-item.button a{padding: 13px 15px 13px 15px;min-width:129px;}
#header .navbar li.nav-item.button.colr {background:transparent;border-color:#fff;color:#f8a700;}
#header .navbar li.nav-item.button.colr:hover{background:#f8a700;border-color:#f8a700;color:#fff;}
#header .navbar li.nav-item.button.colr:hover span,#header .navbar li.nav-item.button.colr:hover a{color:#fff;}
#header .navbar li.nav-item.button.light {background:#f8a700;border-color:#f8a700;color:#fff;}
#header .navbar li.nav-item.button.light:hover{background:transparent;border-color:#fff;color:#fff;}
#header .navbar li.nav-item.button.light:hover span,
#header .navbar li.nav-item.button.light:hover a{color:#fff;}
.navbar-dark .navbar-toggler {color: #f8a700 !important;border:none;padding:0;border-radius:0;background:none;margin-right: 0;position: absolute;right: 67px;top:4px;}
.navbar-dark .navbar-toggler:focus{outline:none;}
.headerProfileImageHolder img{width:30px;height:30px;margin:0 5px 0 0;border-radius:100%;overflow: hidden;}
.mobile-counter-block > div{float:left;}
.counter-block > div.desktop-counter{margin-left:32px;}
.counter-block{display:flex;align-items:center;color:#fff}
.mobile-counter-block{display:none;position: absolute;top: 4px;right: 90px;}
.counter-block .mathpoints-counter{position: relative;}
.counter-block .mathpoints-counter .counter{position:absolute;top:-19px;right:0;color:#fff;padding:0 4px;background:#414650;font-size:12px;border-radius:3px;}
#project-by.hidden,.header-panda-logo.hidden {display: none !important;}
#header .navbar .profile a{width: 170px;height: 38px;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;padding: 6px 0 0;}
.dropdown.show .dropdown-toggle:after{-o-transform: rotate(180deg);-ms-transform: rotate(180deg);-moz-transform: rotate(180deg);-webkit-transform: rotate(180deg);transform: rotate(180deg);}
@media (min-width: 992px){
	.navbar-expand-lg .navbar-nav .nav-link{padding:0 !important;}
}
#header .navbar li.nav-item .dropdown-menu.show,.nav-item.dropdown.profile .dropdown-menu{background: #414650;border: none;border-radius: 0 0 6px 6px;padding:0;box-shadow: 0 2px 8px 2px rgba(0, 0, 0, .1);left: auto;right: 0;top:calc(100% + 8px);overflow: hidden;font-family: 'Lato';font-weight:bold;}
#header .navbar li.nav-item .dropdown-menu.show .dropdown-item,.nav-item.dropdown.profile .dropdown-item {border-bottom: none;padding-bottom: 5px;color: #fff;padding: 10px;font-weight: 300;font-size: 15px;line-height: 16px;}
#header .navbar li.nav-item .dropdown-menu.show .dropdown-item.active,#header .navbar li.nav-item .dropdown-menu.show .dropdown-item:hover,.nav-item.dropdown.profile .dropdown-item.active,.nav-item.dropdown.profile .dropdown-item:hover {color: #fff;background: #f8a700;}
#header.header-scrolled {box-shadow: -21.213px 21.213px 30px 0 rgba(158, 158, 158, .3);background: rgba(255, 255, 255, .9);transition: all .5s;}
@media (min-width: 992px){
	.navbar-expand-lg .navbar-collapse {display: flex !important;flex-basis: auto;}
	.navbar-nav {display: block;}
	.navbar-nav li {float: left;margin: 0 30px 0 0;}
}
@media only screen and (max-width:1919px){
	#header .container{max-width:1375px;}
	.navbar-nav li{margin:0 25px 0 0;}
}
@media only screen and (max-width:1589px){
	#header .container{max-width:1190px;}
	#header .logo{max-width:100%;}
	#header .navbar li.nav-item.button a {padding: 8px 15px 9px 15px;min-width: 99px;}
	#header .navbar .left-nav{padding:0;}
	.navbar-nav li{margin:0 20px 0 0;}
}
@media only screen and (max-width:1399px){
	#header{min-height:96px;}
	#header .container{max-width:1100px;}
	.navbar-nav li{margin:0 16px 0 0;}
}
@media(max-width:1319px){
	#header .navbar .logo img {width: 125px;}
	.navbar-brand.logo {padding-left: 20px;margin-right: 10px}
	.highZindex .navbar-brand.logo,.sticky .navbar-brand.logo {padding-left: 0}
}
@media(max-width:1300px){
	#header .main-menu {padding-top: 0;padding: 0 20px;}
	#header .navbar li.nav-item a,#header .navbar li.nav-item span {font-size: 14px;}
}
@media(max-width:1219px) {
    #header .navbar li.nav-item a,
    #header .navbar li.nav-item span {font-size: 13px;}
	#header .container{max-width:1060px;}
    #header .navbar li.nav-item.button.colr {margin-left: 5px;}
    #header .navbar li.nav-item.button {padding: 0 0;margin-right: 5px;text-align: center;}
	#header .main-menu {padding-top: 0;padding: 0 10px;}
	li.nav-item.dropdown.profile {margin-left: 0;}
	#header .navbar {width: 100%;padding-top: 5px;padding-bottom: 5px;padding-left: 0;padding-right: 0;}
	.navbar-brand.logo {padding-left: 32px}
	.highZindex .navbar-brand.logo,.sticky .navbar-brand.logo {padding-left: 0}
}
@media(max-width:1199px) {
	#header{min-height:80px;}
	#header .container{max-width:970px;}
	#header .counter-block{margin:0 7px 0 10px;}
	#header .counter-block > div.desktop-counter{margin:0 0 0 10px;}
	#header .navbar .logo img {width: 150px;}
	#header .navbar li.nav-item a,
	#header .navbar li.nav-item span {color: #fff;font-weight: 600;font-size: 12px;line-height: 15px;white-space: nowrap;}
	#header .navbar li.nav-item.button{margin-left:5px;}
}
@media(max-width:1054px) {
	#header .container{max-width:880px;}
	#header .navbar li.nav-item a,
	#header .navbar li.nav-item span {padding: 8px 4px 4px;}
	#header .navbar li.nav-item.button a {padding: 8px 8px 9px;min-width: 80px;}
	.navbar-nav li{margin:0 8px 0 0;}
	#header .counter-block{margin:0;}
	#header .counter-block > div.desktop-counter{margin:0 10px;}
	#header .navbar li.nav-item span.headerProfileImageHolder{width:25px;height:25px;}
	/* .headerProfileImageHolder img{width:100%;height:100%;} */
	span.user-name {display: none;}
	#header .navbar .profile a {width: 90px;}
}
@media(max-width:991px) {
	#header{min-height:76px;}
	#header .logo{max-width:105px;margin-left:30px;}
	.pos-stat{position:static !important;}
	#header.highZindex{background:#1c202a;}
	#header .logo{max-width:112px;}
	#header.highZindex .logo{margin:0;}
	#header .navbar{padding-top:0;padding-bottom:0;}
	#header .container{max-width:855px;}
	#header .navbar{position:absolute;left:0;right:0;top:23px;}
	#header .navbar li.nav-item.button.colr {margin-left: 0;margin-bottom: 10px;}
	#header .navbar li.nav-item.button {margin-top: 5px;width: 100%;max-width: 290px;margin: 5px auto 5px!important;}
	#header .navbar li.nav-item.button a {border-bottom: 0;color: #fff;}
	#header .navbar li.nav-item a,#header .navbar li.nav-item span {border-bottom: 1px solid #f8a700;padding: 12px 15px;font-size: 16px;font-weight: 300;color: #fff;}
	#header .navbar li.nav-item.profile span{border-bottom: none;}
	/* #header .navbar li.nav-item.button.colr {margin-top: 15px!important;} */
	.navbar-nav li{margin:0;}
	#header.highZindex {z-index: 999999999!important;position:relative;}
	#header .navbar li.nav-item span.headerProfileImageHolder {padding: 0;overflow: hidden;border: 2px solid #f8a700;border-radius:100%;}
	.navbar-collapse {position: absolute;left:0;top: 53px;right:0;z-index: 9999999;background: #1c202a;max-height: calc(100vh - 58px);min-height: calc(100vh - 58px);overflow-y: auto;padding: 0 0 10px;}
	.navbar-toggler {position: absolute;right: 48px;top: 19px;}
	.navbar-collapse.mobileBrowser{max-height: calc(100vh - 81px);min-height: calc(100vh - 81px);}
	#header .navbar li.nav-item:last-child span{border-bottom:none;}
	#header .navbar li:hover{background:#f8a700;color:#fff;}
	#header .navbar li.nav-item a:hover, #header .navbar li.nav-item span:hover, #header .navbar li.nav-item.show a{color:#fff;}
	#header .navbar .left-nav li:after{display:none;}
	.mobile-counter-block{display:block;}
	.desktop-counter-block{display:none;}
	.user-btns-nav li{width:100%;}
	#header .navbar li.nav-item .dropdown-menu.show, .nav-item.dropdown.profile .dropdown-menu{position: relative;left: auto;top: auto;right:auto;border-radius:0;box-shadow:none;width:100%;border-bottom:1px solid #f8a700;}
	.user-btns-nav .nav-item.profile a{display:flex;align-items:center;}
	.user-btns-nav .nav-item.profile a span{width:32px;height:32px;margin-right:5px ;}
	#header .navbar li.nav-item .dropdown-menu.show,.nav-item.dropdown.profile .dropdown-menu{background:#1c202a;}
	#header .navbar .profile a{width:100%;height:auto;}
	#header .navbar .profile a .user-name{padding-top:7px;}
	#header .navbar li.nav-item span.headerProfileImageHolder{display:inline-block;vertical-align: top;}
	li.nav-item.dropdown.profile{margin:0 0 65px;}
	span.user-name {display: inline;}
}
@media(max-width:767px) {
	#header .navbar .logo img {width: 180px;}
	.navbar-toggler{top:23px;}
	.navbar-collapse.mobileBrowser{max-height: calc(100vh - 76px);min-height: calc(100vh - 76px);}
}
@media(max-width:575px) {
	.user-btns-nav{flex-direction:column;padding:0 10px;}
}
@media(max-width:450px) {
	#header .navbar .logo img {width: 130px;}
	.navbar-toggler{top:15px;}
}
@media only screen and (max-width:319px){
	#header {margin:0 0 41px;}
	.sticky .navbar-dark .navbar-toggler{right:10px;}
	.mobile-counter-block{
		top:52px;right:0;left:0;padding:10px 10px 10px 0;
		background:#1c202a;
		text-align: right;
	}
	.pos-fix .mobile-counter-block{display:none;}
	.mobile-counter-block.menu-opened{top:-10px;right:21px;padding:14px 10px 14px 0;background:none;z-index:1;}
	.mobile-counter-block > div{
		float:none;
		display:inline-block;vertical-align: top;
		margin:0 0 0 10px;
	}
}